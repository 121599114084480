import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, withModifiers as _withModifiers } from "vue";
var _hoisted_1 = {
  class: "title"
};
var _hoisted_2 = {
  class: "content"
};
var _hoisted_3 = {
  class: "indicator"
};
var _hoisted_4 = {
  class: "actions"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_SwipeItem = _resolveComponent("SwipeItem");
  var _component_Swipe = _resolveComponent("Swipe");
  var _component_van_icon = _resolveComponent("van-icon");
  var _component_vue_final_modal = _resolveComponent("vue-final-modal");
  return _openBlock(), _createBlock(_component_vue_final_modal, {
    modelValue: $data.show,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.show = $event;
    }),
    title: "title",
    classes: "noticeModal"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t("global.notice")), 1), _createElementVNode("div", _hoisted_2, [_createVNode(_component_Swipe, {
        ref: "Swipe",
        class: "swiper"
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.list, function (item, index) {
            return _openBlock(), _createBlock(_component_SwipeItem, {
              key: index
            }, {
              default: _withCtx(function () {
                return [_createTextVNode(_toDisplayString($options.renderContent(item.content)), 1)];
              }),
              _: 2
            }, 1024);
          }), 128))];
        }),
        _: 1
      }, 512), _createElementVNode("div", _hoisted_3, [_createVNode(_component_van_icon, {
        name: "arrow-left",
        size: "30",
        class: "arrow-left",
        onClick: $options.handleToPrev
      }, null, 8, ["onClick"]), _createVNode(_component_van_icon, {
        name: "arrow",
        size: "30",
        class: "arrow-right",
        onClick: $options.handleToNext
      }, null, 8, ["onClick"])])]), _createElementVNode("div", _hoisted_4, [_createElementVNode("button", {
        class: "btn btn--orange",
        onClick: _cache[0] || (_cache[0] = _withModifiers(function ($event) {
          return $data.show = false;
        }, ["prevent"]))
      }, _toDisplayString(_ctx.$t("global.confirm")), 1)])];
    }),
    _: 1
  }, 8, ["modelValue"]);
}